.Logo{
  display: flex;
  justify-content: center;
  align-items: center;
  height: auto;
  margin-top: 2rem;
}

@media (max-width: 480px) {

  .Logo{
    width: 80%;
    max-width: 448px;
    min-width: 350px;
  }
}

@media (min-width: 481px) {
  
  .Logo{
    width: 448px;
  }
}

.Logo img {
  width: 100%;
}