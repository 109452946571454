footer{
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  max-width: 980px;
  min-width: 350px;
  height: 48px;
  cursor: default;
}

footer h5{
  font: bold .48rem 'MyCustomFont', sans-serif;
  letter-spacing: 1.4px;
  color: #dac7bc;
}

footer a:link{
  font: bold .48rem 'MyCustomFont', sans-serif;
  text-decoration: none;
  letter-spacing: 1.4px;
  color: #dac7bc;
}

footer a:visited{
  color: #dac7bc;
}

footer a:hover{
  color: #dac7bc;
  opacity: .8;
}

footer a:active{
  color: #dac7bc;
}