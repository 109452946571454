.Insta{
  display: flex;
  justify-content: center;
  align-items: center;
  width: auto;
  height: auto;
}

.InstaLogo{
  width: 112px;
  border-radius: 1.6rem;
}