header{
  display: flex;
  justify-content: center;
  align-items: flex-start;
  width: 100%;
  max-width: 980px;
  min-width: 350px;
  height: 444px;
  z-index: 5;
}

.purpose {
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  margin: -1px;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  border: 0;
}
