@media (max-width: 480px) {
  /* Styles for small smartphones */
}

@media (min-width: 481px) and (max-width: 767px) {
  /* Styles for larger smartphones */
}

@media (min-width: 768px) and (max-width: 991px) {
  /* Styles for portrait tablets */
}

@media (min-width: 992px) and (max-width: 1199px) {
  /* Styles for landscape tablets */
}

@media (min-width: 1200px) and (max-width: 1599px) {
  /* Styles for laptops */
}

@media (min-width: 1600px) {
  /* Styles for large desktops */
}

* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  scroll-behavior: smooth;
}

@font-face {
  font-family: 'MyCustomFont';
  src: url('./fonts/myNewFont.woff2') format('woff2'),
       url('./fonts/myNewFont.woff') format('woff'),
       url('./fonts/myNewFont.otf') format('opentype');
  font-weight: normal;
  font-style: normal;
}


body {
  display: flex;
  justify-content: center;
  align-items: center;
  width: auto;
  height: auto;
  background: #876b5d;
}

.App{
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100vw;
  min-width: 350px;
  height: 100dvh;
  min-height: 632px;
}





