.Booking {
  display: flex;
  justify-content: center;
  align-items: center;
}

.BookingBtn {
  padding: 1rem 2rem;
  letter-spacing: 1.4px;
  border-top-right-radius: 10px;
  border-bottom-left-radius: 10px;
  transition: all 1s;
  cursor: pointer;
  position: relative;
}

@media (max-width: 480px) {
  
  .BookingBtn {
    font: bold 1.2rem 'MyCustomFont', sans-serif;
  }
}

@media (min-width: 481px) {
  
  .BookingBtn {
    font: bold 1.32rem 'MyCustomFont', sans-serif;
  }
}

.BookingBtn:after, .BookingBtn:before {
  content: " ";
  width: 10px;
  height: 10px;
  position: absolute;
  border: 0px solid transparent;
  transition: all 1s;
}

.BookingBtn:after {
  top: -1px;
  left: -1px;
  border-top: 5px solid #dac7bc;
  border-left: 5px solid #dac7bc;
}
.BookingBtn:before {
  bottom: -1px;
  right: -1px;
  border-bottom: 5px solid #dac7bc;
  border-right: 5px solid #dac7bc;
}
.BookingBtn:hover {
  border-top-right-radius: 0px;
  border-bottom-left-radius: 0px;
}
.BookingBtn:hover:before, .BookingBtn:hover:after {
  width: 100%;
  height: 100%;
}

.BookingBtn:link{
  text-decoration: none;
  color: #dac7bc;
}

.BookingBtn:visited{
  color: #dac7bc;
}

.BookingBtn:hover{
  color: #dac7bc;
}

.BookingBtn:active{
  color: #dac7bc;
}

